<h1 style="margin-top: 3%; margin-left: 30%">Submit Your Resume</h1>
<p style="margin-top: 3%; margin-left: 2%">
    Please complete the information form below and submit your resume.
    A member of our recruiting team will contact you soon to discuss your qualifications. 
    Your information is kept strictly confidential and will not be shared with any outside party.
</p>
<form #ResumeForm = "ngForm" (ngSubmit)="sendEmail($event)">
<div class="panel panel-primary">
    <div class="panel-body">
    <div class="form-group" style="margin-top: 3%; margin-left: 5%">
                                    
        <mat-form-field class="example-full-width" style="width:50%">
            <mat-label>First Name</mat-label>
            <input [(ngModel)]="ResumeModel.FirstName"  matInput [formControl]="firstNameFormControl" [errorStateMatcher]="matcher" matInput placeholder="Please enter First Name" name="FirstName">
            <mat-error *ngIf="firstNameFormControl.hasError('required')">
                Last Name is <strong>required</strong>
              </mat-error>  
        </mat-form-field>
    </div>
    <div class="form-group" style="margin-top: 3%; margin-left: 5%">                                 
        <mat-form-field class="example-full-width" style="width:50%">
            <mat-label>Last Name</mat-label>
            <input [(ngModel)]="ResumeModel.LastName" matInput [formControl]="lastNameFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Please enter Last Name" name="LastName">
            <mat-error *ngIf="lastNameFormControl.hasError('required')">
                Last Name is <strong>required</strong>
              </mat-error>  
        </mat-form-field> 
    </div>
    <div class="form-group" style="margin-top: 3%; margin-left: 5%">                                 
        <mat-form-field class="example-full-width" style="width:50%">
            <mat-label>Email</mat-label>
            <input [(ngModel)]="ResumeModel.EmailAddress" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" matInput placeholder="Please enter Email" name="EmailAddress">
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>  
        </mat-form-field> 
    </div>
    <div class="form-group" style="margin-top: 3%; margin-left: 5%">                                 
        <mat-form-field class="example-full-width" style="width:50%">
            <mat-label>Phone Number</mat-label>
            <input [(ngModel)]="ResumeModel.PrimaryPhone"  matInput [formControl]="phoneNumberFormControl" [errorStateMatcher]="matcher" placeholder="Please enter Phone Number" name="PrimaryPhone">
            <mat-error *ngIf="phoneNumberFormControl.hasError('pattern') && !phoneNumberFormControl.hasError('required')">
                Please enter a valid Phone Number
              </mat-error>
              <mat-error *ngIf="phoneNumberFormControl.hasError('required')">
                Phone Number is <strong>required</strong>
              </mat-error>  
        </mat-form-field> 
    </div>
    <div class="form-group" style="margin-top: 3%; margin-left: 5%">                                 
        <mat-form-field class="example-full-width" style="width:50%">
            <mat-label>Address</mat-label>
            <input [(ngModel)]="ResumeModel.Address"  matInput placeholder="Please enter Your Address,City,State and Zip" name="Address">
          </mat-form-field> 
    </div>          
    <div class="form-group" style="margin-top: 3%; margin-left: 5%">
        <div class="app-padding">
                <mat-file-upload [labelText]="'Select a file (or multiple) to upload:'" [selectButtonText]="'Choose File(s)'"
                [uploadButtonText]="'Upload'" [allowMultipleFiles]="true" [showUploadButton]="true"
                (uploadClicked)="onUploadClicked($event)"
                [customSvgIcon]="'close_custom'" [acceptedTypes]="'.png, .jpg, .jpeg, .docx, .pdf'">
                </mat-file-upload>         
        </div>
    </div>
    <div class="form-group" style="margin-top: 3%; margin-left: 20%">       
        <div class="col-md-8">
            <button type="submit" class="btn btn-primary">Submit Resume Details</button>
        </div>
    </div>
                   
</div>
</div>
</form>