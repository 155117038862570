import { Component, OnInit } from '@angular/core';


export interface PeriodicElement {
  position: number;
  JobId: number;
  JobDesc: string;
}

let ELEMENT_DATA: PeriodicElement[] = [
 
]
@Component({
  templateUrl: './openings.component.html',
  styleUrls: ['./openings.component.css']
})
export class OpeningsComponent   {
  displayedColumns: string[] = ['position', 'Job Id', 'Job Description'];
  dataSource = ELEMENT_DATA;
 
}
