import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, Routes } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './home.Mastercomponent.html',
  styleUrls: ['./home.component.css']
})
export class MasterComponent implements OnInit {
  routeLinks: any[];
  activeLinkIndex = -1;
  background = 'primary';
constructor(private router: Router) {
      this.routeLinks = [
          {
              label: 'Home',
              link: './home',
              index: 0
          }, {
              label: 'About',
              link: './about',
              index: 1
          }, {
              label: 'Services',
              link: './services',
              index: 2
          }, {
              label: 'Technology',
              link: './technology',
              index: 3
          }, {
            label: 'Our Process',
            link: './process',
            index: 4,
        },{
          label: 'Careers',
          link: './careers',
          index: 5
      },{
          label: 'Contact',
          link: './contact',
          index: 6
      }
      ];
  }
ngOnInit(): void {
      this.router.events.subscribe((res) => {
          this.activeLinkIndex = this.routeLinks.indexOf(this.routeLinks.find(tab => tab.link === '.' + this.router.url));
      });
  }
}


