<img class="logo" src="assets/OurProcesses.png" style="width:100%;height:60%">
<div class="container">
  <div class="row" style="padding-top: 4%;">
      <div class="col-md-6">
        <h1>Candidate Screening Process</h1>
        <h3 style="font-family:karla; font-size: 20px">
        We follow a detailed screening methodology that is designed to find the best candidates for your business. 
        Once we receive applications, we shortlist them based on some basic criteria. 
        After that, we do the initial screening, technical evaluation & HR screening in order to have a list of candidates to call for the technical interview. 
        Once that is completed, we do detailed reference checks before finally shortlisting them for you. 
        Our experienced recruiters will be working as per your specific requirements & ensure that you are able to easily fill vacant positions
        </h3>
      </div>
      <div class="col-md-6">
        <img class="logo" src="assets/candidate-screening-process-1024x751.jpg" style="width:80%">
      </div>
  </div>
  <div class="row" style="padding-top: 4%;">
      <div class="col-md-6">
          <img class="logo" src="assets/screening-candidate-1024x923.jpg" style="width:80%">
      </div>
      <div class="col-md-6">
          <h1>Screening Candidate</h1>
          <h3 style="font-family:karla; font-size: 20px">
              As an IT staffing company, we follow a method for screening candidates that finds the best talent among the large pool of interested job seekers. 
              Once we receive resumes, we conduct job interviews to learn more about them. 
              After that we go for reference checks so that we can proceed ahead with the genuine candidates only, After that,
              we go for Job simulations & skills assessment tests to find if they are actually suited for the job we need so our clients can get their work done.
          </h3>
      </div>
  </div>
</div>
