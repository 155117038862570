<img class="logo" src="assets/contact_us.webp" style="width:100%;height:60%;">

<div class="container-fluid">
    <div class="row" style="padding-top: 5%; padding-left: 15%;padding-right: 15%">
            <div class="col-md-6">
              
                  <h3>About Us</h3>

                   <p style="padding-top:4%">At Vintek Technologies, we help our customers think attract, engage and retain top talent. 
                     We offer a number of services to help organizations position themselves for the future of work. 
                     These services include:</p>
                     <ol>
                        <li>Payroll</li>
                        <li>Contract Staffing</li>
                        <li>Executive Search</li>
                        <li>Managed Service Programs</li>
                        <li>Professional Services</li>
                     </ol>
                    <p>
                        Today, we work with over 50 leading global organizations to drive impact for their HR and procurement teams.
                        We pride ourselves on value creation, thought leadership and exceptional service.
                    </p>
                    <hr class="solid">
                    <h3 style="padding-top:4%">Our Contact Information</h3>
<div class="row">
  <div style="margin-left:15%;margin-top: 2%">
      <i class="fa fa-map-marker" aria-hidden="true" style="margin-left: 0;"></i>
  </div>
  <div style="margin-left:5%;;margin-top: 3%">
      <p>6880 Brookleigh OverLook, Cumming, GA, 30040</p>
  </div>
 
</div>     

<div class="row">
    <div style="margin-left:13%;margin-top: 4%">
        <i class="fa fa-envelope" aria-hidden="true" style="margin-left: 0;"></i>
    </div>
    <div style="margin-left:5%;;margin-top: 5%">
        <a href="mailto:info@vintekit.com" target="_blank" rel="noopener noreferrer">info@vintekit.com</a>
    </div>
</div>   

<div class="row">
    <div style="margin-left:14%;margin-top: 6%">
        <i class="fa fa-phone" aria-hidden="true" style="margin-left: 0;"></i>
    </div>
    <div style="margin-left:5%;;margin-top: 7%">
        <a href="tel:+16304563776" rel=" noopener">+1 630 456 3776</a>
    </div>
</div> 
      
<hr class="solid">
<div>
 <p style="padding-left:3%">Follow us:</p>
 <div>
 <ul style="list-style: none;margin-top: 5%">
   <li style="display:inline-block">
      <a href="https://www.facebook.com/MetasysTechnologies/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
   </li>
   <li style="display: inline-block;">
      <a href="https://twitter.com/metasystech"><i class="fa fa-twitter" aria-hidden="true"></i></a>
   </li>
   <li style="display: inline-block;">
      <a href="https://www.linkedin.com/company/metasys-technologies-inc-/"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
   </li>
 </ul>
</div>
</div>


                  </div>
        <div class="col-md-6">
            <mat-card class="example-card"style="margin-left:1%;height: 100%;">
                <mat-card-header>
                    <mat-card-title style="margin-left:120px;"> Please submit below form</mat-card-title>
                      </mat-card-header>
                            <mat-card-content style="margin-left:100px;">
                                  <form class="example-form" (ngSubmit)="sendContactEmail($event)">  
                                          <div class="form-group" style="margin-top: 3%">
                                    
                                              <mat-form-field class="example-full-width" style="width:70%">
                                                  <mat-label>First Name</mat-label>
                                                  <input [(ngModel)]="ContactModel.FirstName"  matInput placeholder="First Name" name="first">
                                                </mat-form-field>
                                         
                                          </div>
                                          <div class="form-group" style="margin-top:5%">
                                                  <mat-form-field class="example-full-width" style="width:70%">
                                                          <mat-label>Last Name</mat-label>
                                                          <input [(ngModel)]="ContactModel.LastName"  matInput [formControl]="lastNameFormControl" [errorStateMatcher]="matcher" placeholder="Last Name" name="last">
                                                        
                                                          <mat-error *ngIf="lastNameFormControl.hasError('required')">
                                                                  Last Name is <strong>required</strong>
                                                                </mat-error>
                                                        </mat-form-field>
                                              </div>
                                              <div class="form-group" style="margin-top:5%">
                                                            <mat-form-field class="example-full-width" style="width:70%">
                                                                  <mat-label>Email</mat-label>
                                                                  <input [(ngModel)]="ContactModel.EmailAddress"  matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                                                                         placeholder="Email Address" name="email">
                                                                 
                                                                  <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                                                    Please enter a valid email address
                                                                  </mat-error>
                                                                  <mat-error *ngIf="emailFormControl.hasError('required')">
                                                                    Email is <strong>required</strong>
                                                                  </mat-error>
                                                                </mat-form-field> 
                                                  </div>
                                                  <div class="form-group" style="margin-top:5%">
                                                      <mat-form-field class="example-full-width" style="width:70%">
                                                            <mat-label>Phone Number</mat-label>
                                                            <input [(ngModel)]="ContactModel.Phone"  matInput [formControl]="phoneNumberFormControl" [errorStateMatcher]="matcher"
                                                                   placeholder="Phone Number" name="phone">
                                                            
                                                            <mat-error *ngIf="phoneNumberFormControl.hasError('pattern') && !phoneNumberFormControl.hasError('required')">
                                                              Please enter a valid Phone Number
                                                            </mat-error>
                                                            <mat-error *ngIf="phoneNumberFormControl.hasError('required')">
                                                              Phone Number is <strong>required</strong>
                                                            </mat-error>
                                                          </mat-form-field> 
                                            </div>
                                            <div class="form-group" style="margin-top:5%">
                                                <mat-form-field class="example-full-width" style="width:70%">
                                                        <mat-label>Subject</mat-label>
                                                        <input [(ngModel)]="ContactModel.Title"  matInput placeholder="Job Title" name="job">
                                                      </mat-form-field>                                                    
                                            </div>
                                                  <div class="form-group" style="margin-top:5%">
                                                          <mat-form-field class="example-full-width" style="width:70%">
                                                                  <mat-label>Subject</mat-label>
                                                                  <input [(ngModel)]="ContactModel.Subject"  matInput placeholder="Subject" name="sub">
                                                                </mat-form-field>                                                    
                                                      </div>
                                                      <div class="form-group" style="margin-top:5%">
                                                              <mat-form-field class="example-full-width" style="width:70%">
                                                                      <mat-label>Message</mat-label>
                                                                      <textarea [(ngModel)]="ContactModel.Message"  matInput placeholder="Message" style="height:150px" name="msg"></textarea>
                                                                    </mat-form-field>                                                             
                                                      </div>
                                                      <div class="form-group" style="margin-top:5%">
                                                        <div class="col-sm-8" style="text-align: center;" >
                                                            <button mat-raised-button color="primary" style="width:40%" >submit</button>  
                                                            <!-- (click)="sendEmail(ContactModel.LastName, ContactModel.EmailAddress, ContactModel.Message)" -->
                                                          </div>
                                                      </div>
                                                          </form>   
                            </mat-card-content>
                            <!-- <mat-card-actions>
                              <button mat-button>LIKE</button>
                              <button mat-button>SHARE</button>
                            </mat-card-actions> -->
                          </mat-card>
        </div>
    </div>   
</div>




