
<!-- <mat-tab-group>
  <mat-tab label="First"> Content 1 </mat-tab>
  <mat-tab label="Second"> Content 2 </mat-tab>
  <mat-tab label="Third"> Content 3 </mat-tab>
</mat-tab-group> -->

<!-- <nav mat-tab-nav-bar>
    <mat-tab [routerLink]="['about']">About</mat-tab>
  <a class="mat-tab-link" [routerLink]= "['services']">Services</a>
  <a class="mat-tab-link" [routerLink]= "['technology']">Technology</a>
  <a class="mat-tab-link" [routerLink]= "['clients']">Clients</a>
  <a class="mat-tab-link" [routerLink]= "['clients']">Clients</a>
  <a class="mat-tab-link" [routerLink]= "['careers']">Careers</a>
  <a class="mat-tab-link" [routerLink]= "['contact']">Contact</a>
</nav> -->

<!-- <nav mat-tab-nav-bar>
    <a mat-tab-link
       *ngFor="let link of routeLinks"
       [routerLink]="link.path"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive">
      {{link.label}}
    </a>
  </nav> -->
  
  <nav mat-tab-nav-bar  [backgroundColor]="background">
      <a class="navbar-brand" href="#" style="width:10%;height:10%">
          <img src="assets/letter-v-with-thunder-initial-logo_8586-63.jpg" alt="" style="width:30%;height:30%">
        </a>
    <div mat-tab-link
       *ngFor="let routeLink of routeLinks;"
       [routerLink]="routeLink.link"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive" >
        {{routeLink.label}}
      </div>
</nav>
<!-- Navigation -->
<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
    <div class="container">
      <a class="navbar-brand" href="#">
            <img src="http://placehold.it/150x50?text=Logo" alt="">
          </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" [routerLink]= "['home']">Home
                  <span class="sr-only">(current)</span>
                </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]= "['about']">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]= "['services']">Services</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" [routerLink]= "['technology']">Technology</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]= "['clients']">Clients</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" [routerLink]= "['careers']">Careers</a>
                </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]= "['contact']">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->

<!-- <nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" [routerLink]= "['home']">Vintek</a>
    </div>
    <ul class="nav navbar-nav" style="display: contents;">
      <li class="active"><a [routerLink]= "['home']">Home</a></li>
      <li class="blog-collection"><a [routerLink]= "['contact']"> Contact Us</a></li>
      <li class="blog-collection"><a [routerLink]= "['services']"> Services</a></li>
      <li class="blog-collection"><a [routerLink]= "['technology']"> Technology</a></li>
      <li class="blog-collection"><a [routerLink]= "['clients']"> Clients</a></li>
      <li class="blog-collection"><a [routerLink]= "['careers']"> Careers</a></li>
      <li class="blog-collection"><a [routerLink]= "['about']"> About</a></li>
    </ul>
  </div>
</nav> -->
<router-outlet></router-outlet>

<footer class="footer">
  <p style="text-align:center">&copy; All rights reserved for <i>Vintek LLC</i> 2020</p>
</footer>