<img class="logo" src="assets/About_us.png" style="width:80%;height:60%;padding-left: 20%;">

<div class="container-fluid">
        <div class="row" style="padding-top: 4%;">
                <div class="col-md-6">
                        <img class="logo" src="assets/recruitment-concept-human-resources_41910-340.jpg" style="width:100%;height:80%;">
                      </div>
            <div class="col-md-6">
                <p>
                        Vintek is a leading Workforce company that has been in the business since 2012.
                        Vinket was formed with the mission of providing world-class technical consultants to our clients.
                        Our goal is to exceed client expectations in all aspects of Workforce Solutions.
                        We seek to know the client's needs and use innovative ways of sourcing and qualifying 
                        the best candidates that fit the overall need in a hands-on way. 
                </p>
                <p>
                        In a word, Vintek offers expertise. 
                        Our network of experienced professionals is so diverse and robust that we can advise and drive success
                        in an amazing variety of disciplines. Whether you are needing to plan the development of the next great mobile app,
                        engage business intelligence professionals to make sense of mountains of data, 
                        or take an objective look at improving your own internal processes, Vintek has the right people to get it done.
                </p>
                <p>
                        Vintek strives to deliver value with an offer of high quality, reliable and affordable
                        <span style="color:orange">IT staffing and Recruitment solutions. </span> We understand the challenges faced by our clients 
                        in the tough and competitive global IT environment. As people and their competencies majorly 
                        influence the success of each IT engagement, we offer value-driven and custom IT staffing solutions.
                 </p>
                 <p>
                  Collective success is our goal and objective.
                  We work with our clients as one team with focus on joint goal-setting and goal-achievement. 
                  This helps us to understand and leverage on our individual strengths and match them with the clients’ expectations of IT staffing and recruitment.
          </p>
            </div>
        </div>   
</div>
        <div class="container-fluid">
                <div class="row" style="padding-top: 2%;">
                    
                    <div class="col-md-6">
                        <p>
                                Vision & Mission      </p>
                        <p>
                                <strong>Vision</strong>: To change the perception, break the status queue and embrace the changes the future of IT staffing and recruiting holds        
                        </p>
                        <p>
                                <strong>Mission</strong>: To remain competitive, reduce cost-per-hire and improve the overall talent profile all directed towards achieving one goal that our clients do not suffer from a lack of pipeline/staff       
                        </p>
                    </div>
                    <div class="col-md-6">
                        <mat-accordion>
                                <!-- #docregion basic-panel -->
                                <!-- #docregion hide-toggle -->
                                  <mat-expansion-panel hideToggle>
                                <!-- #enddocregion hide-toggle -->
                                    <mat-expansion-panel-header>
                                      <mat-panel-title> 
                                        Methodology
                                      </mat-panel-title>
                                     
                                    </mat-expansion-panel-header>
                                 
                                  </mat-expansion-panel>
                                <!-- #enddocregion basic-panel -->
                                  <mat-expansion-panel (opened)="panelOpenState = true"
                                                       (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        Philosophy
                                      </mat-panel-title>
        
                                    </mat-expansion-panel-header>
                                    <p>
                                        We provide dependable and quality staffing that is an essential component of our turnkey approach. 
                                        We offer our clients the benefits of unlimited coverage, unparalleled access and unequaled value. 
                                        Our full service IT staffing and recruiting services includes SOW, Direct-Hire Staffing, 
                                        Recruitment Outsourcing, and Payroll Solutions.
                                    </p>
                                  </mat-expansion-panel>
                                  <mat-expansion-panel (opened)="panelOpenState = true"
                                  (closed)="panelOpenState = false">
               <mat-expansion-panel-header>
                 <mat-panel-title>
                        Business Ethics
                 </mat-panel-title>
                
               </mat-expansion-panel-header>
               <p>
                We adhere and uphold all the ethical and legal guidelines.
                We follow the ethical staffing procedures that prohibit various kinds of discrimination. 
                We also follow the stringent confidentiality agreements and strict safety policies.
               </p>
             </mat-expansion-panel>

             <mat-expansion-panel (opened)="panelOpenState = true"
                                  (closed)="panelOpenState = false">
               <mat-expansion-panel-header>
                 <mat-panel-title>
                        Customer Centricity
                 </mat-panel-title>

               </mat-expansion-panel-header>
               <p style="font-family:Fira Sans;color:#000000; line-height: 26px; font-size: 16px">
                We understand that, finding the right personnel capable of doing the job and being 
                customer-centric are daunting tasks. Our customer-centric approach avoids 
                the ‘post and pray’ recruiting approach, promotes hiring people with demonstrated 
                potential beyond the role expected, and adopting continuous improvement to get better incrementally.
               </p>
             </mat-expansion-panel>

             <mat-expansion-panel (opened)="panelOpenState = true"
                                  (closed)="panelOpenState = false">
               <mat-expansion-panel-header>
                 <mat-panel-title>
                        Nimble in Spirit
                 </mat-panel-title>

               </mat-expansion-panel-header>
               <p>
                We strive successfully by being at the forefront of IT staffing and recruiting innovation 
                and has acquired various milestones by delivering premium personnel services that are customizable, 
                value-driven, competitive and automated.
               </p>
             </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="container-fluid">
                <div class="row" style="padding-top: 4%;">
                        <div class="col-md-6">
                                <img class="logo" src="assets/recruitment-concept-human-resources_41910-340.jpg" style="width:100%;height:100%;">
                              </div>
                    <div class="col-md-6">
                                <mat-list>
                                                <mat-list-item>Dynamism</mat-list-item> 
                                                <p>
                                                   A belief that dynamism is the fundamental strength of a company. 
                                                   Our group of young, enterprising and experienced team make sure 
                                                   transactions are carried on in a professional and in a time bound manner.
                                                </p>
                                                <mat-divider></mat-divider>
                                                <mat-list-item>Reliability</mat-list-item>
                                                <p>
                                                    TechCognize has been committed to provide reliable and consistent service without disruption. 
                                                    Our reliability levels have shown customers coming back for more and providing valuable referrals.
                                                </p>
                                                <mat-divider></mat-divider>
                                                <mat-list-item>Innovativeness</mat-list-item>
                                                <p>
                                                Innovation is a collective resource that TechCognize provides to its customers in bringing them world-class solutions and services. 
                                                Innovation is all around us. TechCognize has what it takes to make things right the first time.
                                                </p>
                                </mat-list>
                    </div>
                </div>   
        </div>
   
