<img class="logo" src="assets/Careers.jpg" style="width:80%;height:60%;padding-left:15% ">

<div class="container-fluid" style="margin-top:5%">
  <div class="col-md-10">
    <h3>
        The nature of work is rapidly changing
    </h3>

    <p>
        Over the last 10 years, the nature of work has <strong>fundamentally changed.</strong> 
        79% of leading Executives expect the future of work to be based on <strong>projects versus specific roles </strong>and by 2020,
        43% of the workforce will consist of <strong>freelancer workers.</strong> 
    </p>

    <p>
        What does this mean for candidates? Companies are increasingly looking to engage candidates in <strong>alternative and flexible models </strong>
        and are <strong>unbundling work requirements. </strong> We are at an inflection point for freelance work; enterprises are embracing new forms of work
         to improve their agility and non-employees are in demand.
    </p>

    <p>
        The nature of work shifting towards projects necessitates <strong>the importance of a non-employee worker base. </strong>
        Despite this shift, employers and candidates report <strong>a highly sub-par experience </strong> in working with talent 
        management firms - candidate NPS across the industry today is an embarrassingly low (21) and employer NPS is even worse (11).
         A highly negative experience that is trending worse with each year.
    </p>
    <p>
       <strong> We actively work to obliterate industry benchmarks.</strong>
        We are your <strong>advocate </strong> and will always work to find the right fit and environment for you.
    </p>
  </div>
<div class="row" style="margin-top:5%">
    <div class="col-sm-6">
        <div class="card" style="width: 45rem;">
            <img src="assets/jobhunt.jpg" class="card-img-top" alt="No Image Found">
            <div class="card-body">
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a class="btn btn-primary" [routerLink]= "['openings']">Current Openings</a>
            </div>
          </div>
    </div>
    <div class="col-sm-6">
        <div class="card" style="width: 45rem;">
            <img src="assets/Resume.png" class="card-img-top" alt="No Image Found">
            <div class="card-body">
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a class="btn btn-primary" [routerLink]= "['submitResume']">Submit Resume</a>
            </div>
          </div>
    </div>
  </div>
</div>
<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
<div class="navbar-nav" id="navbarResponsive">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]= "['openings']">openings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]= "['submitResume']">submit Resume</a>
      </li>
    </ul>
  </div>
</nav> -->
<router-outlet></router-outlet>