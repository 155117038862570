import { Component, OnInit,OnDestroy } from '@angular/core';
import{Contact} from './contact.model'
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { Injectable } from '@angular/core';
// import { SendMailServiceService } from '../MailServices/send-mail-service.service';
import { Subscription } from 'rxjs';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
@Injectable()
export class ContactComponent{
 
  public subscription: Subscription;
  constructor() { }
  matcher = new MyErrorStateMatcher();
  ContactModel : Contact = new Contact();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  lastNameFormControl = new FormControl('', [
    Validators.required
  ]);
  phoneNumberFormControl = new FormControl('', [
    Validators.required,Validators.pattern(("[2-9]\\d{9}"))
  ]);

  public sendContactEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_7xuz6vs', 'template_lstr5i8', e.target as HTMLFormElement, 'user_Jnktrgjs4d6HEnEWuG5yk')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }
  // sendEmail(name, email, message) {
  //   const obj = {
  //     name: name,
  //     email: email,
  //     message: message,
  //   };
  //   this.subscription = this.sendmailservice.sendEmail(obj).
  //   subscribe(data => {
  //     let msg = data['message']
  //     alert(msg);
  //      // console.log(data, "success");
  //   }, error => {
  //     console.error(error, "error");
  //   } );
// }
// ngOnInit() {
// }
// ngOnDestroy() {
// }
}


