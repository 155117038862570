import {HomeComponent} from "../home/home.component";
import {ContactComponent} from "../contact/contact.component";
import {ServicesComponent} from "../services/services.component";
import {TechnologyComponent} from "../technology/technology.component";
import {CareersComponent} from "../careers/careers.component";
import {AboutComponent} from "../about/about.component";
import {OpeningsComponent} from "../openings/openings.component";
import {SubmitResumeComponent} from "../submit-resume/submit-resume.component";
import { ProcessComponent } from '../process/process.component';
export const MainRoutes = [
    {path: 'home', component: HomeComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'technology', component: TechnologyComponent},
    {path: 'process', component: ProcessComponent},
    {path: 'careers', component: CareersComponent,
    children: [
        {
          path: 'openings', // child route path
          component: OpeningsComponent // child route component that the router renders
        },
        {
          path: 'submitResume',
          component: SubmitResumeComponent // another child route component that the router renders
        }
              ]
    },
    {path: 'about', component: AboutComponent},
    {path: '', component: HomeComponent}
]