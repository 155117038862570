<p>Current openings</p>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

   <!-- Position Column -->
   <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
  </ng-container>

  <!-- Job ID  Column -->
  <ng-container matColumnDef="Job Id">
    <th mat-header-cell *matHeaderCellDef> Job Id </th>
    <td mat-cell *matCellDef="let element"> {{element.JobId}} </td>
  </ng-container>

   <!-- Job Description  Column -->
   <ng-container matColumnDef="Job Description">
    <th mat-header-cell *matHeaderCellDef> Job Description </th>
    <td mat-cell *matCellDef="let element"> {{element.JobDesc}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>