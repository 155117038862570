import {Component, Host} from '@angular/core';
import {Resume} from './resume.model';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import './../../../src/assets/smtp.js';
import { FormControl, FormGroupDirective, NgForm,Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
declare let Email: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  templateUrl: './submit-resume.component.html',
  styleUrls: ['./submit-resume.component.css']
})

export class SubmitResumeComponent 
{
  ResumeModel : Resume = new Resume();
  matcher = new MyErrorStateMatcher();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  firstNameFormControl = new FormControl('', [
    Validators.required
  ]);
  lastNameFormControl = new FormControl('', [
    Validators.required
  ]);
  phoneNumberFormControl = new FormControl('', [
    Validators.required,Validators.pattern(("[2-9]\\d{9}"))
  ]);
  onUploadClicked(files: FileList) {
    this.ResumeModel.fileToUpload = files; 
  }
  
  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_7xuz6vs', 'template_u3tf5pe', e.target as HTMLFormElement, 'user_Jnktrgjs4d6HEnEWuG5yk')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

//  public sendEmail(f: NgForm) {

//     Email.send({
//       Host : 'smtp.elasticemail.com',
//       Port: '2525',
//       Username : 'vinithknet@gmail.com',
//       Password : '619E17476D5ECD0C42285EB43CCAB156A2AC',
//       To : 'vinithknet@gmail.com',
//       From : this.ResumeModel.EmailAddress,
//       Subject : 'Important!!! New Resume Email for VinTek',
//       Body : `
//       <i>This is sent as a feedback from my resume page.</i> <br/> <b>Name: </b>${this.ResumeModel.FirstName} <br /> <b>Email: </b>${this.ResumeModel.EmailAddress}<br /> <b>Phone: </b>${this.ResumeModel.PrimaryPhone}<br /> <b>Address:</b> <br /> ${this.ResumeModel.Address} <br><br> <b>~End of Message.~</b> `
//       }).then( message => {alert(message); f.resetForm(); 
//       }); 
//   }
}

