<img class="logo" src="assets/Home.png" style="width:80%;height:60%;padding-left: 20%">

<div class="row" style="margin-top:3%;margin-left:5%">
<mat-card >
    <mat-card-title style="text-align: center;">About</mat-card-title>
  <mat-card-content>
    <p>
            Vintek is an IT consulting and professional services firm <br>
            based in Cumming, GA, USA.<br>
            We aim to provide clients exceptional IT technicians <br>
            and consultants to meet the demanding technology <br>
            challenges of today...
    </p>
  </mat-card-content>
</mat-card>
<div class="col">
<mat-card >
    <mat-card-title style="text-align: center;">Services</mat-card-title>
  <mat-card-content>
    <p>
            Vintek is an IT consulting and professional services firm <br>
            based in Cumming, GA, USA.<br>
            We aim to provide clients exceptional IT technicians <br>
            and consultants to meet the demanding technology <br>
            challenges of today...
    </p>
  </mat-card-content>
</mat-card>
</div>
<div class="col">
<mat-card >
    <mat-card-title style="text-align: center;">Technology</mat-card-title>
  <mat-card-content>
    <p>
            Vintek is an IT consulting and professional services firm <br>
            based in Cumming, GA, USA.<br>
            We aim to provide clients exceptional IT technicians <br>
            and consultants to meet the demanding technology <br>
            challenges of today...
          </p>
  </mat-card-content>
</mat-card>
</div>
</div>