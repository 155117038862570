<img class="logo" src="assets/globalisation.png" style="width:80%;height:60%;padding-left: 20%;">

<div style="padding-right: 10%;padding-left: 10%;padding-top: 2%;font-family:sans-serif; font-size: 15px">
<p>
    Vintek is an IT consulting and professional services firm based in Alpharetta, GA, USA. We aim to provide clients exceptional IT technicians and consultants to meet the demanding technology challenges of today
</p>
<p>
    We believe that when you have the right people with the right skill set, background and professionalism you can easily achieve your overall IT goals. Quality talent is essential to ensuring your IT projects are succussful. Through our thorough and quality based "exact match" recruiting process we consistently deliver top notch IT professionals which not only meet the technical requirements of your position but also the "soft skills" which are necessary in your unique environment/culture.
</p>
<p>
    Vintek is committed to servicing its clients and IT professionals, which is demonstrated in the company's industry leading growth. Our services can scale from staff augmentation to fully outsourced services, depending on the level of responsibility you want us to assume. Additionally, we can leverage our global delivery models to ensure cost-competitive solutions without sacrificing quality outcomes.
</p>
<p>
    In addition to project-based and outsourcing services, we provide Managed IT Services for applications and infrastructure needs. Our Managed IT Services offer a scalable delivery model to help you achieve your business objectives. Through this model, we manage a team of high-performance resources that can be customized based on your needs. We also define, streamline and optimize how resources accomplish in-scope work outcomes to lower your total cost of ownership and achieve higher levels of performance through defined SLAs.
</p>
<p>
    We have supported hundreds of application development engagements while consistently delivering high-quality outcomes. By utilizing coding standards, architecture and design best practices, our application solutions offer a flexible foundation for future growth, security and ease of maintainability.
</p>
</div>