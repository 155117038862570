<img class="logo" src="assets/connected-technology.jpg" style="width:100%;height:60%">
<div style="padding-right: 10%;padding-left: 10%;padding-top: 2%;">
       

    <h1>INFORMATION TECHNOLOGY</h1> 

    <h2>DEVELOPING, MAINTAINING, MODERNIZING AND MANAGING IT SYSTEMS</h2>

<p>The federal government’s renewed focus on information technology via the recent FITARA and IT modernization bills, along with cybersecurity-related initiatives, is driving investments in areas like cloud computing, mobile computing, artificial intelligence, machine intelligence, and more. VINTEK has provided IT solutions over the course of nearly few years, giving us the experience to help agencies not only deliver on promises of efficiency and transparency, but also help to change their reputation in the eyes of their users, transforming perceived failure into award-winning programs.</p>
 
<div class="container-fluid">
        <div class="row" style="padding-top: 2%;">
            
            <div class="col-md-6">
                <p> Technologies Include  </p>
               <ul>
                   <li>Developing new IT systems</li>
                   <li>Maintaining legacy systems</li>
                   <li>Modernizing legacy systems</li>
                   <li>Managing data centers</li>
                   <li>Managing networks and infrastructure</li>
                   <li>Spatial + GIS technologies</li>
                   <li>Mainframes</li>
                   <li>Technology Infrastructure</li>
                   <li>National Disaster Technologies</li>
                   <li>Cyber Security</li>
                   
               </ul>
            </div>
            <div class="col-md-6">
                    <p>  </p>
                    <ul>
                            <li>Aviation and Airspace Technologies</li>
                            <li>Spectrum and Cyber Capabilities</li>
                            <li>Shared Services</li>
                            <li>Health and Benefits Technologies</li>
                            <li>Certified cloud services for Infrastructure as a Service (IaaS) and Platform as a Service (PaaS)</li>
                            <li>Open Source tools</li>
                            <li>Business Intelligence solutions</li>
                            <li>Big Data solutions</li>
                            <li>Mobile development</li>
                            <li>Single Sign on Authentications and Authorizations</li>
                            
                    </ul>
            </div>
</div>